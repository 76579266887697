import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../scss/style.scss';

import select from 'dom-select';
import throttle from 'lodash.throttle';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min';
import Swiper from 'swiper/swiper-bundle.min';
import { classy as cl } from './helpers/classy';
import { scrollLock, scrollUnLock } from './helpers/scrollLock';
import Spoilers from './helpers/spoilers';
import LazyLoad from 'lazyload';
import axios from './helpers/axios';
import serialize from 'form-serialize';
import Cookie from 'js-cookie';

import { notificationCookie } from './notificationCookie';

window.addEventListener('popstate', function () {
  cl('.overlay').rmCl('open');
});

(function (doc, win) {
  notificationCookie.init();
  require('viewport-units-buggyfill').init();
  window.openModal = false;
  window.dataLayer = window.dataLayer || [];
  const app = {};

  function isIe() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  }

  function scLock(target) {
    scrollLock(target, (w) => {
      const header = select('.header');
      if (header) {
        header.style.right = w + 'px';
      }
    });
  }

  function scUnlock() {
    scrollUnLock(() => {
      const header = select('.header');
      if (header) {
        header.style.right = '';
      }
    });
  }

  //
  // Set all external links target to new tab
  //
  function openExternalLinksNewTab() {
    document
      .querySelectorAll(
        'a:not([target="_blank"]):not([class*="glightbox"]):not([class^="#"])'
      )
      .forEach((link) => {
        const href = link.getAttribute('href');
        const containsHost = href.search(`${window.location.hostname}`);
        const NotLocal = href.charAt(0) !== '/' && href.charAt(0) !== '#';
        // console.log(NotLocal);
        if (containsHost === -1 && NotLocal) {
          link.setAttribute('target', '_blank');
        }
      });
  }

  const nav = function () {
    const header = select('.header');
    const megaHover = select('.desktop-menu .nav-link.is-mega-hover', header);
    const megaLeave = select.all(
      '.desktop-menu .nav-link:not(.is-first) span, .side-nav',
      header
    );
    const cartCount = select('.menu-footer .cart-count', header);

    if (header && megaHover && megaLeave.length) {
      megaHover.addEventListener('mouseover', () => {
        cl(header).addCl('mega-active');
      });
      header.addEventListener('mouseleave', () => {
        cl(header).rmCl('mega-active');
      });
      megaLeave.forEach((l) => {
        l.addEventListener('mouseover', () => {
          cl(header).rmCl('mega-active');
        });
      });
    }

    this.update = function (container) {
      if (container.classList.contains('has-wh')) {
        cl(header).addCl('is-white');
      }
    };

    this.scroll = function () {
      if (
        window.scrollY > window.innerHeight / 4 &&
        !header.classList.contains('w-bg')
      ) {
        cl(header).addCl('w-bg');
      } else if (
        window.scrollY <= window.innerHeight / 4 &&
        header.classList.contains('w-bg')
      ) {
        cl(header).rmCl('w-bg');
      }
    };

    this.cartCount = cartCount;
  };

  function activeNav() {
    const lastSegment =
      location.pathname === '/' ? '/' : location.pathname.split('/').pop();
    select.all('.header a, .nav a, .foot-link').forEach(function (nav) {
      const linkLastSegment =
        nav.pathname === '/' ? '/' : nav.pathname.split('/').pop();
      if (linkLastSegment === lastSegment) cl(nav).addCl('active');
      else cl(nav).rmCl('active');
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600,
      });
    }
  }

  function setSmooth(parent) {
    select.all('.smooth-scroll', parent).forEach((anchor) => {
      anchor.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (anchor.dataset.window) {
          smooth(window.innerHeight, 0, 1000);
        } else {
          const target = select(anchor.hash);
          if (target) smooth(target, 65, 1000);
        }
      });
    });

    if (window.location.hash) {
      const target = select(window.location.hash);
      if (target) {
        setTimeout(() => {
          smooth(target, 65, 1000);
        }, 500);
      }
    }

    select.all('.scroll-to-next', parent).forEach((anchor) => {
      anchor.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (anchor.nextElementSibling) {
          smooth(anchor.nextElementSibling, 65, 1000);
        }
      });
    });
  }

  app.swipers = [];
  function swipers(parent) {
    app.swipers.forEach((sw) => sw.destroy());

    select.all('.swiper-regular', parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          speed: 1000,
          loop: true,
          pagination: {
            type: 'bullets',
            clickable: true,
            el: select('.swiper-pagination', sw),
          },
          navigation: {
            nextEl: select('.swiper-nav.control-right', sw),
            prevEl: select('.swiper-nav.control-left', sw),
          },
        })
      );
    });

    select.all('.swiper--reviews', parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          speed: 500,
          loop: true,
          autoplay: {
            delay: 5000,
          },
          draggable: false,
          pagination: {
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination',
          },
        })
      );
    });

    select.all('.swiper--visit-hero', parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          speed: 500,
          loop: true,
          autoplay: {
            delay: 5000,
          },
          draggable: false,
          pagination: {
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination',
          },
        })
      );
    });

    select.all('.swiper-products', parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          speed: 1000,
        })
      );
    });

    setTimeout(function () {
      app.swipers.forEach((sw) => sw.update());
    }, 200);
  }

  app.lazyload = null;
  function setLazy(parent) {
    if (app.lazyload) app.lazyload.destroy();
    const images = select.all('.lazyload', parent);
    app.lazyload = new LazyLoad(images);
  }

  function cookieBar() {
    const coockieVal = Cookie.get('cookie-bar');
    const bar = select('.cookie-bar');
    const button = select('.button', bar);

    if (!coockieVal) {
      setTimeout(() => {
        cl(bar).addCl('open');
      }, 5000);
    }

    if (button) {
      button.addEventListener('click', () => {
        cl(bar).rmCl('open');
        Cookie.set('cookie-bar', 'closed', { expires: 30 });
      });
    }
  }

  const indexItems = function (parent) {
    const hoverArea = select('.shop-section .index-items', parent);
    const hoverHeader = select('.index-hover-headline', parent);
    const indexItems = select.all('.index-item', hoverArea);
    const shopCategories = select.all('.shop-category', parent);
    const hoverHeaders = select.all('div', hoverHeader);
    const hoverText = select('.index-hover-text');
    const hoverTexts = select.all('div', hoverText);

    function setActive(index) {
      reset();
      cl(hoverHeader).addCl('show');
      cl(hoverText).addCl('show');
      cl(hoverHeaders).rmCls('show');
      cl(hoverTexts).rmCls('show');
      if (indexItems[index]) cl(indexItems[index]).addCl('active');
      if (hoverHeaders[index]) cl(hoverHeaders[index]).addCl('show');
      if (hoverTexts[index]) cl(hoverTexts[index]).addCl('show');
    }

    function reset() {
      cl(hoverHeader).rmCl('show');
      cl(indexItems).rmCls('active');
      cl(hoverText).rmCl('show');
      cl(hoverHeaders).rmCls('show');
      cl(hoverTexts).rmCls('show');
    }

    if (hoverArea && hoverHeader && hoverText) {
      indexItems.forEach((item, i) => {
        item.addEventListener('mouseover', () => {
          if (window.innerWidth >= 1024) {
            setActive(i);
          }
        });

        item.addEventListener('click', () => {
          const target = select(item.hash);
          smooth(target, window.innerWidth >= 768 ? 65 : 40);
        });
      });

      //hoverArea.addEventListener('mouseleave', reset)
    }

    this.scroll = function () {
      if (window.innerWidth >= 1024) {
        const activeIndex = shopCategories.findIndex((cat) => {
          const rect = cat.getBoundingClientRect();
          const top = rect.top;
          const bottom = rect.bottom;
          return top <= 65 && bottom > 65;
        });

        if (activeIndex > -1) {
          setActive(activeIndex);
        } else {
          reset();
        }
      }
    };
  };

  function labelHovers(parent) {
    const wrap = select('.labels', parent);
    const hoverAreas = select.all('.labels .index-items', wrap);
    const hovers = select.all('.index-item', wrap);
    const items = select.all('.label-item', wrap);

    hovers.forEach((hover) => {
      hover.addEventListener('mouseover', () => {
        const target = items.find(
          (i) => i.dataset.hover === hover.dataset.hoverTarget
        );
        if (target && !target.classList.contains('active')) {
          cl(items).rmCls('active');
          cl(target).addCl('active');
        }
      });
    });

    hoverAreas.forEach((hoverArea) => {
      hoverArea.addEventListener('mouseover', () => {
        cl(wrap).addCl('is-hovered');
      });

      hoverArea.addEventListener('mouseleave', () => {
        cl(wrap).rmCl('is-hovered');
      });
    });
  }

  function mapClicks(parent) {
    select.all('.map-wrap', parent).forEach((wrap) => {
      const svg = select('svg', wrap);
      const paths = select.all('g[id*="items"] path', svg);
      const infos = select.all('.info', wrap);

      paths.forEach((path, i) => {
        path.addEventListener('click', () => {
          console.log('map section #' + (i + 1));

          if (
            path.classList.contains('active') &&
            wrap.classList.contains('is-hovered')
          ) {
            cl(wrap).rmCl('is-hovered');
          } else if (!wrap.classList.contains('is-hovered')) {
            cl(wrap).addCl('is-hovered');
          }

          cl(paths).rmCls('active');
          cl(infos).rmCls('active');
          if (infos[i] && !infos[i].classList.contains('active')) {
            cl(infos[i]).addCl('active');
          }
          cl(path).addCl('active');
        });
      });

      wrap.addEventListener('click', (ev) => {
        if (!ev.target.closest('g[id*="items"]')) {
          cl(wrap).rmCl('is-hovered');
          cl(infos).rmCls('active');
          cl(paths).rmCls('active');
        }
      });
    });
  }

  function getWeather(parent) {
    select.all('.weather-wrap.live', parent).forEach((wrap) => {
      const api = window.weatherApi;
      const lat = wrap.dataset.lat;
      const lng = wrap.dataset.lng;
      const weatherEl = select('.weather-el', wrap);
      const tempEl = select('.temp-el', wrap);
      const timeEl = select('.time-el', wrap);

      if (api && lat && lng && weatherEl && tempEl) {
        axios(
          `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${api}&mode=json&units=metric`
        ).then(({ data }) => {
          weatherEl.innerHTML = data['weather'].length
            ? data['weather'][0].description
            : null;
          tempEl.innerHTML = data['main']['temp']
            ? Math.round(data['main']['temp']) + '°C'
            : null;
          timeEl.innerHTML = new Date()
            .toLocaleTimeString()
            .replace(/(.*)\D\d+/, '$1');
        });
      }
    });
  }

  function inputs(parent) {
    select.all('.input', parent).forEach((input) => {
      if (input.value !== '') cl(input).addCl('filled');
      input.addEventListener('focus', function (el) {
        cl(el.target).addCl('filled');
      });
      input.addEventListener('focusout', function (el) {
        if (
          input.value === '' &&
          input.type !== 'date' &&
          input.type !== 'time'
        )
          cl(el.target).rmCl('filled');
      });
      input.addEventListener('change', function (el) {
        cl(el.target).addCl('filled');
      });
    });
  }

  function search() {
    const search = select('.search-form');
    const searchTo = select('.search-trig');
    const searchInput = select('#search');

    if (search) {
      search.addEventListener('submit', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.target.action) {
          window.modals.forEach((m) => m.close());
          cl('.overlay').addCl('open');
          cl('.header').rmCl('mega-active');
          cl('.search-trig').rmCl('open');

          setTimeout(() => {
            const query = serialize(ev.target);
            window.location.href = ev.target.action + '?' + query;
          }, 300);
          // const query = serialize(ev.target);
          // barba.go(ev.target.action+'?'+query)
        }
      });
    }

    if (searchInput) {
      searchInput.addEventListener('blur', () => {
        cl(searchTo).rmCl('open');
      });
    }

    if (searchTo) {
      searchTo.addEventListener('click', () => {
        if (!searchTo.classList.contains('open') && searchInput) {
          setTimeout(() => {
            searchInput.focus();
          }, 10);
        }
        cl(searchTo).toCl('open');
      });
    }
  }

  function forms(parent) {
    select.all('.sign-up-form, .exp-form form', parent).forEach((form) => {
      const button = select('button', form);
      let posting = false;

      function reset() {
        posting = false;
        form.classList.remove('is-loading');
        button.removeAttribute('disabled');
        setTimeout(() => {
          form.reset();
          form.forEach((f) => {
            if (f.type !== 'date' && f.type !== 'time') {
              cl(f).rmCl('filled');
            }
          });
          window.modals.forEach((m) => m.close());
          form.classList.remove('is-posted');
        }, 3000);
      }

      form.addEventListener('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        form.classList.add('is-loading');
        button.setAttribute('disabled', 'disabled');

        if (!posting) {
          posting = true;
          axios
            .post(`/actions/${form.action.value}`, serialize(form))
            .then(({ data }) => {
              form.classList.add('is-posted');
              reset();
              if (!data.success) {
                console.log(data);
              }
            })
            .catch((e) => {
              reset();
              console.log(e);
            });
        }
      });
    });
  }

  const slowPan = function (parent) {
    parent = parent || document;
    const mainWrap = select('.slowpan-wrap', parent);
    const container = select('.slowpan-container', mainWrap);
    let containers = select.all('.slowpan-container', mainWrap);
    let inners = select.all('.content', mainWrap);
    const qty = container
      ? Math.ceil(window.innerWidth / container.clientWidth) + 1
      : 1;
    let contQty = containers.length;

    if (container) {
      container.style.width = container.clientWidth + 'px';
    }

    this.resize = function () {
      if (mainWrap) {
        cl(mainWrap).rmCl('animated');

        for (let i = 1; i <= qty; i++) {
          if (contQty < qty) {
            const newCont = container.cloneNode(true);
            mainWrap.appendChild(newCont);
            containers = select.all('.slowpan-container', mainWrap);
            inners = select.all('.content', mainWrap);
            contQty = containers.length;
          }
        }

        containers.forEach(function (cont, index) {
          const width = inners[0].clientWidth * (inners.length / contQty);
          cont.style.width = width + 'px';
          if (index + 1 > qty) cont.remove();
          if (index > 0) cont.style.left = width * index + 'px';
        });

        cl(mainWrap).addCl('animated');
      }
    };

    this.resize();
  };

  function openModal(modal) {
    if (window.activeModal) {
      closeModal(window.activeModal, true);
    }
    if (!modal.open) {
      modal.open = true;
      window.activeModal = modal;
      cl(modal.modal).addCl('open');
      cl(modal.trigger).addCl('active');
      if (modal.modal.closest('.header')) {
        cl('.header').addCl('has-' + modal.name + '-open');
      }
      scLock(modal.modal);
    }
    if (modal.modal.classList.contains('gallery-modal') && app.swipers.length) {
      setTimeout(() => {
        app.swipers.forEach((m) => m.update());
      }, 400);
    }
  }

  function closeModal(modal, unlockBefore) {
    if (unlockBefore) {
      scUnlock();
    }
    if (modal.open) {
      modal.open = false;
      window.activeModal = null;
      cl(modal.modal).rmCl('open');
      cl(modal.trigger).rmCl('active');
      if (modal.modal.closest('.header')) {
        cl('.header').rmCl('has-' + modal.name + '-open');
      }
      setTimeout(() => {
        modal.modal.scrollTo(0, 0);
        if (!unlockBefore) {
          scUnlock();
        }
      }, 300);
      if (modal.cookieTime) {
        Cookie.set('modal-closed-' + modal.name, 'closed', {
          expires: modal.cookieTime,
        });
      }
      checkDelayedModals();
    }
  }

  function checkDelayedModals() {
    const delayedModals = window.modals.filter(
      (m) => m.delayed !== null && !Cookie.get('modal-closed-' + m.name)
    );
    const firstModal = delayedModals.length
      ? delayedModals.reduce((a, b) => (a.delayed < b.delayed ? a : b))
      : null;

    if (firstModal) {
      setTimeout(() => {
        firstModal.open();
      }, firstModal.delayed * 1000);
    }
  }

  window.modals = [];
  function setModals(parent) {
    select.all('[data-modal]', parent).forEach((modal) => {
      const open = modal.classList.contains('open');
      const name = modal.dataset.modal;
      const delayed = modal.dataset.modalDelayed
        ? Number(modal.dataset.modalDelayed)
        : null;
      const cookieTime = modal.dataset.modalCookieTime
        ? Number(modal.dataset.modalCookieTime)
        : null;
      const triggerAsData = select(`[data-modal-target="${name}"]`, parent);
      const triggerAsHref = select(`a[href*="modal-${name}"]`, parent);
      const trigger = triggerAsData
        ? triggerAsData
        : triggerAsHref
        ? triggerAsHref
        : null;
      const modalEl = { name, modal, open, delayed, cookieTime, trigger };

      if (trigger) {
        trigger.addEventListener('click', (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          if (trigger.classList.contains('active')) {
            closeModal(modalEl);
          } else {
            openModal(modalEl);
          }
        });
      }

      const prevIndex = window.modals.findIndex((m) => m.name === name);
      if (prevIndex > -1) {
        window.modals.splice(prevIndex, 1);
      }
      window.modals.push({
        ...modalEl,
        open() {
          openModal(modalEl);
        },
        close() {
          closeModal(modalEl);
        },
      });

      select.all('.close, .modal-back, .close-button', modal).forEach((cl) => {
        cl.addEventListener('click', (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          closeModal(modalEl);

          if (cl.hasAttribute('data-cookie')) {
            setCookie(cl);
          }
        });
      });
    });

    checkDelayedModals();
  }

  function setCookie(el) {
    if (el.dataset.cookie && el.dataset.cookie.indexOf('|') > -1) {
      const parts = el.dataset.cookie.split('|');
      if (parts.length === 3) {
        Cookie.set(parts[0], parts[1], { expires: Number(parts[2]) });
      }
    }
  }

  function setTabs(parent) {
    const triggs = select.all('[data-tab]', parent);
    const tabs = select.all('.tab', parent);
    triggs.forEach((tabClick) => {
      tabClick.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        const target = select('#' + tabClick.dataset.tab);
        if (target) {
          cl(triggs).rmCls('active');
          cl(tabs).rmCls('active');
          cl(tabClick).addCl('active');
          cl(target).addCl('active');
        }
      });
    });

    select.all('.tab-select', parent).forEach((s) => {
      s.addEventListener('change', (ev) => {
        const targets = select.all('#' + ev.target.value);
        if (targets.length) {
          cl(triggs).rmCls('active');
          cl(tabs).rmCls('active');
          cl(targets).addCls('active');
        }
      });
    });
  }

  function destroyCommerce7() {
    select.all('script[src*="commerce7"]').forEach((s) => s.remove());
    select.all('body>div[id*="c7-"]').forEach((s) => s.remove());
    document.body.classList.forEach((c) => {
      if (c.indexOf('c7') > -1) {
        cl('body').rmCl(c);
      }
    });
    if (app.cartObserver) {
      app.cartObserver.disconnect();
    }
  }

  function initCommerce7(initial) {
    const cartWrap = select('.cart-wrap');
    const checkoutWrap = select('.page-checkout');
    if (cartWrap) {
      let init = true;

      app.cartObserver = new MutationObserver(() => {
        const cartEl = select('.c7-cart');
        const errorEl = select('.c7-message--error', cartWrap);
        const countEl = select('.c7-cart__count', cartWrap);
        const totalEl = select('.c7-cart__total', cartWrap);
        const loadingEl = select('.c7-cart__loading', cartWrap);
        const open = cartEl && cartEl.classList.contains('c7-visible');

        const detail = {
          init,
          open,
          error: errorEl ? errorEl.innerHTML : null,
          count: countEl ? +countEl.innerHTML : null,
          total: totalEl ? totalEl.innerHTML : null,
          loading: !!loadingEl,
        };

        window.dispatchEvent(new CustomEvent('cart7', { detail }));
        init = false;
      });

      app.cartClassObserver = new MutationObserver((m) => {
        if (m.length) {
          const target = m[0].target;
          if (
            target.classList.contains('c7-cart') &&
            target.classList.contains('c7-visible')
          ) {
            cl(cartWrap).addCl('open');
          } else if (
            target.classList.contains('c7-cart') &&
            !target.classList.contains('c7-visible')
          ) {
            cl(cartWrap).rmCl('open');
          }
        }
      });

      if (cartWrap && !checkoutWrap) {
        app.cartObserver.observe(cartWrap, {
          attributes: false,
          childList: true,
          subtree: true,
        });
      }

      if (checkoutWrap) {
        app.cartObserver.observe(checkoutWrap, {
          attributes: false,
          childList: true,
          subtree: true,
        });
      }
    }
  }

  function handleCart(ev) {
    const cart = ev.detail;
    const body = document.body;

    if (app.nav && app.nav.cartCount) {
      app.nav.cartCount.innerHTML = cart.count;
    }

    if (cart.init) {
      cl(body).addCl('c7-global_loaded');
      select.all('.c7-product__variant__add-to-cart').forEach((button) => {
        button.addEventListener('click', () => {
          cl(button).addCl('is-loading');
        });
      });

      const cartEl = select('.c7-cart');
      const cartOver = select('.cart-wrap .modal-back');
      const close = select('.c7-cart__close', cartEl);
      if (cartEl) {
        app.cartClassObserver.observe(cartEl, {
          attributes: true,
          childList: false,
          subtree: false,
        });

        if (cartOver && close) {
          cartOver.addEventListener('click', () => {
            close.click();
          });
        }
      }

      clickTransition(select('.c7-cart'));
      clickTransition(select('.c7-page-cart'));
    }

    if (cart.loading) {
      cl(body).addCl('c7-global_loading');
    } else {
      cl(body).rmCl('c7-global_loading');
      select.all('.c7-buy-variant').forEach((button) => {
        cl(button).rmCl('is-loading');
      });

      if (
        !cart.init &&
        ['/cart', '/checkout'].indexOf(window.location.pathname) === -1
      ) {
        cl(body).addCl('c7-global_updated');
        setTimeout(() => {
          cl(body).rmCl('c7-global_updated');
        }, 1000);
      }
    }
  }

  function clickTransition(parent = doc) {
    select.all('a:not([href^="#"]):not([target])', parent).forEach((a) => {
      a.addEventListener('click', (ev) => {
        cl('.header .nav-link').rmCls('active');
        cl(a).addCl('active');
        cl('.c7-cart').rmCl('c7-visible');
        ev.preventDefault();
        ev.stopPropagation();
        window.modals.forEach((m) => m.close());
        cl('.overlay').addCl('open');
        cl('.header').rmCl('mega-active');
        setTimeout(() => {
          window.location.href = a.href;
        }, 300);
      });
    });
  }

  function giftCardSelector() {
    // Click on trigger opens gift card form on product page

    let trigger = document.querySelector('#gift-card-select');
    let giftCardForms = document.querySelectorAll('.gift-voucher');

    if (trigger) {
      trigger.addEventListener('change', (e) => {
        let value = trigger.options[trigger.selectedIndex].value;

        let giftCardForm = document.querySelector(
          '.gift-voucher[data-sku="' + value + '"]'
        );

        giftCardForms.forEach((form) => {
          form.classList.add('hidden');
        });

        giftCardForm.classList.remove('hidden');
      });
    }
  }

  function outBarba() {
    app.nav = new nav();
    search();
    initCommerce7(true);
  }

  app.videRes = null;
  function onBarba(parent) {
    //activeNav()
    if (parent) {
      try {
        openExternalLinksNewTab();
        swipers(parent);
        setSmooth(parent);
        setLazy(parent);
        setModals(parent);
        setTabs(parent);
        inputs(parent);
        forms(parent);
        labelHovers(parent);
        mapClicks(parent);
        getWeather(parent);
        giftCardSelector();
        app.indexItems = new indexItems(parent);
        app.spoilers = new Spoilers(parent);
        app.slowPan = new slowPan(parent);
        //app.videRes = new videoResize('.has-cover-video')
      } catch (e) {
        console.error(e);
      }
    }
  }

  doc.addEventListener('DOMContentLoaded', function () {
    outBarba();
    onBarba(doc);
    setTimeout(() => {
      cl('.overlay').rmCl('open');
    }, 100);

    clickTransition();

    win.addEventListener(
      'scroll',
      throttle(function () {
        if (app.nav) app.nav.scroll();
        if (app.indexItems) app.indexItems.scroll();
      }, 200)
    );

    win.addEventListener(
      'resize',
      throttle(function () {
        if (app.throw) app.throw.resize();
        if (app.slowPan) app.slowPan.resize();
        // if (app.videRes) app.videRes.resize();
      }, 300)
    );

    win.addEventListener('cart7', handleCart);
  });
})(document, window);
